
import { Customer, useCustomerStore } from '@/stores/customer';
import { defineComponent, ref } from 'vue';
import { fileIcon } from '@/components/icon/BpIcon';
import { url } from '@sahnee/ajax';
import BpCustomerEditorVue from '@/components/customer/BpCustomerEditor.vue';
import { useFormErrors } from '@/utils/form-errors';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-license-certificate-generator-view',
  components: {
    BpCustomerEditor: BpCustomerEditorVue
  },
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const customerStore = useCustomerStore(true);

    ///-------------------------------------------------------------------
    /// CUSTOMER
    ///-------------------------------------------------------------------

    const selected = ref('');
    const customer = ref<Partial<Customer>>({});

    function updateCustomer(id: string) {
      selected.value = id;
      customer.value = clone(customerStore.getById(id));
      if (customer.value && customer.value.products?.bestinformed.version === '6' && customer.value.editionProduct !== 'Apps') {
        customer.value.apps = customerStore.getBestinformedApps(customer.value as Customer);
        customer.value.editionApps = customerStore.getBestinformedEdition(customer.value as Customer);
        customer.value.editionProduct = 'Apps';
        console.log('customer is ', customer.value);
      }
    }

    const date = ref('');

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<Customer & { customer: string, date: string }>();

    ///-------------------------------------------------------------------
    /// GENERATE CERTIFICATE
    ///-------------------------------------------------------------------

    async function generateCertificate() {
      window.open(
        url({
          url:'/api/get/certificate',
          search: {
            customer: window.btoa(JSON.stringify(customer.value)),
            date: date.value || new Date().toISOString(),
          }
        })
      )
    }

    return {
      customerStore,
      customer,
      date,
      errors,
      fileIcon,
      generateCertificate,
      updateCustomer,
      selected,
      setError,
    }
  }
})
