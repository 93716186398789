import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-license-certificate-generator-view" }
const _hoisted_2 = { class: "flex-column gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_customer_editor = _resolveComponent("bp-customer-editor")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      image: "/static/background/app_background_certifiacate_generator.jpg",
      icon: ['fad','file-certificate']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("License certificate generator")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      "fill-height": "",
      "fill-width": ""
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          icon: _ctx.fileIcon('pdf'),
          errors: _ctx.errors,
          action: _ctx.generateCertificate
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Create certificate")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["icon", "errors", "action"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_bp_select, {
            data: _ctx.customerStore.customers,
            label: _ctx.$gettext('Customer'),
            "model-value": _ctx.selected,
            onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('customer', _ctx.$gettext('Customer'), $event))),
            "onUpdate:modelValue": _ctx.updateCustomer,
            required: "",
            "search-property": "company"
          }, null, 8, ["data", "label", "model-value", "onUpdate:modelValue"]),
          _createVNode(_component_bp_input, {
            label: _ctx.$gettext('Issue date'),
            onChangeValid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setError('date', _ctx.$gettext('Issue date'), $event))),
            type: "date",
            modelValue: _ctx.date,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event))
          }, null, 8, ["label", "modelValue"]),
          _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
          _createVNode(_component_bp_customer_editor, {
            "ignore-vtiger": "",
            unwrap: "",
            modelValue: _ctx.customer,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer) = $event)),
            excluded: [
          'company', 'dcpc', 'pilot', 'reseller', 'containsReseller', 'accountid', 'commentfield', 'supportCancel', 'hostingCancel', 'products.bestinformed.installedVersion', 'products.bestproxy.installedVersion', 'products.bestzero.installedVersion'
        ],
            onChangeValid: _cache[4] || (_cache[4] = (id, name, message) => _ctx.setError(id, name, message))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    })
  ]))
}